import React from "react"
import RequestForComments from "./request_for_comments"

const Standards = () => (
  <section>
    <h3>Standards</h3>
    <p>
      Contributed to {"\u2015"}
    </p>
    <ul>
      <li><RequestForComments name='Sieve Email Filtering:  Sieves and Display Directives in XML' number='5784'/></li>
      <li><RequestForComments name='A Protocol for Remotely Managing Sieve Scripts' number='5804'/></li>
      <li><RequestForComments name='Sieve Extension: Externally Stored Lists' number='6134'/></li>
    </ul>
  </section>
)

export default Standards
