import React from "react"
import PropTypes from "prop-types"

const RequestForComments = ({ number, name }) => (
  <span>
    <a href={`https://tools.ietf.org/html/rfc${number}`} rel="tag noopener noreferrer"
       target="_blank">RFC{number}</a> {name}
  </span>
)

RequestForComments.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}

export default RequestForComments
