import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Standards from "../components/standards"

const StandardsPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <Standards/>
  </Layout>
)

export default StandardsPage
